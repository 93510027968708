"use client"

import clsx from "clsx"

import Slider from "@unlikelystudio/react-slider"

import type { Nullish, PropsWithClassName } from "~/@types/generics"
import useLocale from "~/hooks/useLocale"
import { FillImage } from "~/components/ui/FillImage"
import type { TImage } from "~/components/ui/Image/_data/serializer"
import InlineCta from "~/components/ui/InlineCta"
import type { TLinkLabel } from "~/components/ui/Link/_data/serializer"
import SquareCta from "~/components/ui/SquareCta"

import { breakpoints } from "~/styles/variables/breakpoints"

import * as css from "./styles.css"

const SIZES = [{ breakpoint: breakpoints.md, ratio: 1 / 2 }, { ratio: 1 }]

export type ErrorProps = PropsWithClassName<{
  suptitle: Nullish<string>
  title: Nullish<string>
  ctaLabel?: Nullish<string>
  image: Nullish<TImage>
  links?: Nullish<TLinkLabel[]>
}>

function Error({ className, title, suptitle, ctaLabel, image, links }: ErrorProps) {
  const locale = useLocale()
  return (
    <div className={clsx(className, css.Error)}>
      <div className={clsx(css.content)}>
        {suptitle && <p className={clsx(css.suptitle)}>{suptitle}</p>}
        {title && <h2 className={clsx(css.title)}>{title}</h2>}
        {ctaLabel && (
          <SquareCta href={`/${locale}`} className={clsx(css.cta)} theme="backgroundBlack">
            {ctaLabel}
          </SquareCta>
        )}
        {links && (
          <div className={clsx(css.linksList)}>
            <Slider>
              {links.map((link, index: number) => (
                <InlineCta className={clsx(css.inlineCta)} key={index} {...link} color="black" size="small" withLine />
              ))}
            </Slider>
          </div>
        )}
      </div>
      {image && <FillImage className={css.image} {...image} sizesFromBreakpoints={SIZES} asPlaceholder />}
    </div>
  )
}

export default Error
